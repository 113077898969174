import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';

export const slotDuration = {
  minutes: 15,
};

export const slotLabelFormat = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  meridiem: false,
  omitZeroMinute: false,
};

export const businessHours = {
  daysOfWeek: [1, 2, 3, 4, 5],
  startTime: '09:00',
  endTime: '17:00',
};

export const columnsHeaderFormat = {
  weekday: 'long',
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const calendarPlugins = [timeGridPlugin, interactionPlugin, listPlugin];
export const resourceCalendarPlugins = [resourceTimelinePlugin];
