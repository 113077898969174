import { ReportType } from '@/models/ReportType';
import { Address, Tag, Report } from '@/models/Report';
import { EventApi, View } from '@fullcalendar/core';
import { AppointmentGroup, AppointmentType, getAppointmentType } from '@/models/Event';

export const renderSimpleEvent = (eventInfo: EventRender): HTMLElement => {
  if (eventInfo.event.extendedProps && eventInfo.event.extendedProps.type) {
    eventInfo.el.classList.add(`fc-custom-event__${eventInfo.event.extendedProps.type}`);
  }

  const eventElementContent = eventInfo.el.querySelectorAll('.fc-content')[0];
  if (! eventElementContent) {
    return eventInfo.el;
  }

  const customContent = renderSimpleEventContent(eventInfo.event);

  if (! customContent) {
    return eventInfo.el;
  }

  const element = document.createElement('div');
  element.classList.add('fc-custom-content');
  element.innerHTML = customContent;
  eventInfo.el.appendChild(element);

  return eventInfo.el;
};

export const renderSimpleEventContent = (event: EventApi) => {
  let htmlString = '';

  if (event.extendedProps && event.extendedProps.note) {
    htmlString += `
    <div class="fc-custom-content-row">
      <span class="fc-custom-content-icon">
        <i aria-hidden="true" class="v-icon material-icons theme--light">assignment</i>
      </span>
      <span class="fc-custom-content-info">
        <span class="fc-custom-content-text">${event.extendedProps.note}</span>
      </span>
    </div>`;
  }

  if (event.extendedProps.address && event.extendedProps.address.street && event.extendedProps.type === 'community_center') {
    htmlString += `<div class="fc-custom-content-row">${generateAddressHtmlString(event.extendedProps.address)}</div>`;
  }

  return htmlString;
};

export const renderHourEvent = (eventInfo: EventRender): HTMLElement => {
  const status = eventInfo.event.extendedProps.status;

  if (eventInfo.view.type === 'listWeek') {
    eventInfo.el.style.backgroundColor = '#F6F9FC';

    if (eventInfo.el.className.includes('fc-all-day-total')) {
      eventInfo.el.innerHTML = '';
      eventInfo.el.innerHTML = renderHourEventListTotalTitle();
      const customContent = renderHourEventListTotalContent(eventInfo.event);
      const listItemTitle = eventInfo.el.getElementsByClassName('fc-list-item-title')[0];
      const element = document.createElement('tr');
      element.classList.add('fc-custom-tr');
      element.innerHTML = customContent;
      listItemTitle.innerHTML = '';
      listItemTitle.appendChild(element);
      return eventInfo.el;
    }

    const dotEl = eventInfo.el.getElementsByClassName('fc-event-dot')[0];
    dotEl.classList.add(`hourEvent--${status || 'created'}`);
    const listItemTitle = eventInfo.el.getElementsByClassName('fc-list-item-title')[0];

    const customContent = renderHourEventListContent(eventInfo.event);

    if (! customContent) {
      return eventInfo.el;
    }

    const element = document.createElement('tr');
    element.classList.add('fc-custom-tr');
    element.innerHTML = customContent;
    listItemTitle.innerHTML = '';
    listItemTitle.appendChild(element);
  } else {
    const eventElementContent = eventInfo.el.querySelectorAll('.fc-content')[0];
    eventInfo.el.classList.add(`hourEvent--${status || 'created'}`);
    if (! eventElementContent) {
      return eventInfo.el;
    }

    const customContent = renderHourEventContent(eventInfo.event);

    if (! customContent) {
      return eventInfo.el;
    }

    const element = document.createElement('div');
    element.classList.add('fc-custom-content');
    element.innerHTML = customContent;
    eventInfo.el.appendChild(element);
  }
  return eventInfo.el;
};

export const renderHourEventListTotalTitle = () => {
  const htmlString = `
  <td class="fc-list-item-time fc-widget-content font-weight-bold">Totaal</td>
  <td class="fc-list-item-marker fc-widget-content"></td>
  <td class="fc-list-item-title fc-widget-content"></td>`;
  return htmlString;
};
export const renderHourEventListTotalContent = (event: EventApi) => {
  const htmlString = `
    <td class="pa-0 fc-custom-td"></td>
    <td class="pa-0 fc-custom-td"></td>
    <td class="pa-0 fc-custom-td">
      <div class="fc-custom-content fc-custom-list-content text-right font-weight-bold">
        ${event.extendedProps.total}
      </div>
    </td>`;
  return htmlString;
};

export const renderHourEventListContent = (event: EventApi) => {
  let htmlString = '';

  if (event.extendedProps && event.extendedProps.type) {
    htmlString += `<td class="pa-0 fc-custom-td">
          <a class="fc-custom-a-tag">${event.title}</a>
        </td>
        <td class="pa-0 fc-custom-td">
          <div class="fc-custom-content fc-custom-list-content">
            <a>
            <span>
              <i aria-hidden="true" class="v-icon material-icons theme--light" style="font-size: 16px;">assignment</i>
            </span>
              ${event.extendedProps.type}
              </a>
          </div>
        </td>
        <td class="pa-0 fc-custom-td">
          <div class="fc-custom-content fc-custom-list-content text-right">
            ${event.extendedProps.total}
          </div>
        </td>`;
  }
  return htmlString;
};

export const renderHourEventContent = (event: EventApi) => {
  let htmlString = '';

  if (event.extendedProps && event.extendedProps.type) {
    htmlString += `
    <div class="fc-custom-content-row">
      <span class="fc-custom-content-icon">
        <i aria-hidden="true" class="v-icon material-icons theme--light">assignment</i>
      </span>
      <span class="fc-custom-content-info">
        <span class="fc-custom-content-text">${event.extendedProps.type}</span>
      </span>
    </div>`;
  }

  return htmlString;
};

export const renderCustomEvent = (eventInfo: EventRender): HTMLElement => {
  if (eventInfo.event.extendedProps && eventInfo.event.extendedProps.type && ! eventInfo.event.extendedProps.appointment_type) {
    eventInfo.el.classList.add(eventInfo.event.extendedProps.type);
  }

  if (eventInfo.event.extendedProps && eventInfo.event.extendedProps.isIMSEvent) {
    eventInfo.el.classList.add('appointment-type--ims');
  }

  if (eventInfo.event.extendedProps && eventInfo.event.extendedProps.appointment_type) {
    eventInfo.el.classList.add(`appointment-type--${eventInfo.event.extendedProps.appointment_type}`);
  }

  if (eventInfo.event.extendedProps?.report?.type?.name === 'IMS') {
    eventInfo.el.classList.add('appointment-type--ims');
  }

  const eventElementContent = eventInfo.el.querySelectorAll('.fc-content')[0];
  const eventElementTime = eventInfo.el.querySelectorAll('.fc-time')[0];

  if (eventInfo.event.extendedProps.report) {
    const appointmentType = getAppointmentType(eventInfo.event.extendedProps.appointment_type);
    if (new Report(eventInfo.event.extendedProps.report).isOpnameVanAfstandReport || (appointmentType && appointmentType.key === AppointmentType.OPNAME_OP_AFSTAND)) {
      eventInfo.el.classList.add('opname-van-afstand');
      const span = document.createElement('div');
      span.classList.add('type-icon');
      span.innerHTML = '<i aria-hidden="true" class="v-icon material-icons theme--light">videocam</i>';
      eventElementTime.appendChild(span);
    }
  }

  if (! eventElementContent) {
    return eventInfo.el;
  }

  if (eventInfo.event.extendedProps.appointment_type) {
    const header = document.createElement('div');
    header.classList.add('appointment-type-label');

    const eventType = getAppointmentType(eventInfo.event.extendedProps.appointment_type);
    header.innerHTML = eventType.abbreviation;
    eventElementTime.appendChild(header);
  }

  const customContent = renderEventContent(eventInfo.event);

  if (! customContent) {
    return eventInfo.el;
  }

  const element = document.createElement('div');
  element.classList.add('fc-custom-content');
  element.innerHTML = customContent;
  eventInfo.el.appendChild(element);

  return eventInfo.el;
};

const renderEventContent = (event: EventApi) => {
  if (! event.extendedProps || ! event.extendedProps.report) {
    return null;
  }

  const report = event?.extendedProps?.report;
  let htmlString = '';

  if (event.extendedProps.address && event.extendedProps.address.street && event.extendedProps.isIMSEvent) {
    htmlString += `<div class="fc-custom-content-row">${generateAddressHtmlString(event.extendedProps.address)}</div>`;
  }

  if (report.address) {
    htmlString += `<div class="fc-custom-content-row">${generateAddressHtmlString(report.address)}</div>`;
  }

  if (report.type) {
    htmlString += `<div class="fc-custom-content-row">${generateTypeHtmlString(report.type)}</div>`;
  }

  if (report.tags && report.tags.length) {
    htmlString += `<div class="fc-custom-content-row">${generateTagsHtmlString(report.tags)}</div>`;
  }

  if (event.extendedProps.appointment_type) {
    htmlString += `<div class="fc-custom-content-row"><span class="fc-custom-content-icon">
      <i aria-hidden="true" class="v-icon material-icons theme--light">settings</i>
    </span>
    <span class="fc-custom-content-info">
      <span class="fc-custom-content-text">${getAppointmentType(event.extendedProps.appointment_type).label}</span>
    </span></div>`;
  }

  if (event.extendedProps.is_mediator_present && event.extendedProps.appointment_group !== AppointmentGroup.ZB) {
    htmlString += `<div class="fc-custom-content-row"><span class="fc-custom-content-icon">
      <i aria-hidden="true" class="v-icon material-icons theme--light">check</i>
    </span>
    <span class="fc-custom-content-info">
      <span class="fc-custom-content-text">Zaakbegeleider aanwezig</span>
    </span></div>`;
  }

  return htmlString;
};

const generateTagsHtmlString = (tags: Tag[]) => {
  const tagsString = tags.map((tag: Tag) => tag.name);

  return `
    <span class="fc-custom-content-icon">
      <i aria-hidden="true" class="v-icon material-icons theme--light">label</i>
    </span>
    <span class="fc-custom-content-info">
      <span class="fc-custom-content-text">${tagsString.join(', ')}</span>
    </span>
  `;
};

const generateTypeHtmlString = (type: ReportType) => `
    <span class="fc-custom-content-icon">
      <i aria-hidden="true" class="v-icon material-icons theme--light">assignment</i>
    </span>
    <span class="fc-custom-content-info">
      <span class="fc-custom-content-text">${type.name ? type.name : ''}</span>
    </span>
  `;

const generateAddressHtmlString = (address: Address) => `
    <span class="fc-custom-content-icon">
      <i aria-hidden="true" class="v-icon material-icons theme--light">home_work</i>
    </span>
    <span class="fc-custom-content-info">
      <span class="fc-custom-content-text">${address.name ? address.name : ''} ${address.street ? address.street : ''} ${address.number ? address.number : ''}</span>
      <span class="fc-custom-content-text">${address.postcode ? address.postcode : ''}, ${address.city ? address.city : ''}</span>
    </span>
  `;

export interface EventRender {
  isMirror: boolean;
  isStart: boolean;
  isEnd: boolean;
  event: EventApi;
  el: HTMLElement;
  view: View;
}
